@charset "utf-8";

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;500&display=swap');

// Set your brand colors
$green: #01bd80;
$blue: #6742df;

// Update Bulma's global variables
$family-sans-serif: 'Montserrat', sans-serif;
$primary: $green;
$secondary: $blue;
$navbar-burger-color: $blue;

@import "../node_modules/bulma/bulma.sass";
@import "../node_modules/bulma-extensions/bulma-timeline/dist/css/bulma-timeline.sass";
@import "../node_modules/bulma-extensions/bulma-pricingtable/dist/css/bulma-pricingtable.sass";

.hero-background {
    background-image: url("static/hero.jpg");
    background-color: $primary;
    background-position-y: top;
    background-position-x: center;
    background-size: cover;
}

.paper-background {
    background-image: url("static/trees.jpg");
    background-color: rgba(0,0,0,0.0);
    background-position-y: top;
    background-position-x: center;
    background-size: cover;
    background-blend-mode: darken;
}

html {
    font-size: 12px;
    @include tablet {
      font-size: 16px;
    }
    @include desktop {
      font-size: 20px;
    }
  }

  hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 1em 0;
    padding: 0;
}

.is-dark-trans-background{
    background: rgba(0, 0, 0 , 0.2);
    padding-bottom: 1rem;
}

.navbar-burger {
  color: #333 !important;
}